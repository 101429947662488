<template>
  <div class="bg-beige">
    <HomeTemporaryHome v-if="isProduction" />
    <template v-else>
      <HomeHero class="z-10" />
      <HomeBrowseCircles />
      <HomeWhats v-if="false" />
      <HomeBenefits v-if="false" class="hidden xl:flex" />
      <HomeBenefitsMobile v-if="false" class="block xl:hidden" />
      <HomeBetterWorld />
      <HomeWeBelieve />
      <HomeSteps />
      <HomeImagine />
      <Footer />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useQueryClient } from "@tanstack/vue-query";
import { organizationQueries } from "~/domains/organization/queries";

useSeoMeta({
  title: "Giving Circles",
});

const queryClient = useQueryClient();
await queryClient.prefetchQuery(organizationQueries.list());

const isProduction = useRuntimeConfig().public.environment === "production";
</script>
